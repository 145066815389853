@custom-media --viewport-tablet (min-width: 768px);
@custom-media --viewport-desktop (min-width: 1024px);

@custom-selector :--heading h1, h2, h3, h4, h5, h6;

#root {
  display: grid;
  flex: 1;
  grid-template-columns: auto;
  grid-template-rows: auto 1fr auto;
  padding-bottom: 96px;
  width: 100%;
}
