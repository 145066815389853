/* Margin Top */
.m-t-2 {
  margin-top: 2rem;
}

.m-t-1 {
  margin-top: 1rem;
}

.m-t-half {
  margin-top: 0.5rem;
}

.m-t-0 {
  margin-top: 0;
}

/* Margin Bottom */
.m-b-2 {
  margin-top: 2rem;
}

.m-b-1 {
  margin-bottom: 1rem;
}

.m-b-half {
  margin-bottom: 0.5rem;
}

.m-b-0 {
  margin-bottom: 0;
}

/* Margin */

.m-2 {
  margin-bottom: 2rem;
  margin-top: 2rem;
}

.m-1 {
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.m-half {
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}

.m-0 {
  margin-bottom: 0;
  margin-top: 0;
}

/* Padding Top */
.p-t-2 {
  padding-top: 2rem;
}

.p-t-1 {
  padding-top: 1rem;
}

.p-t-half {
  padding-top: 0.5rem;
}

.p-t-0 {
  padding-top: 0;
}

/* Padding Bottom */
.p-b-2 {
  padding-top: 2rem;
}

.p-b-1 {
  padding-bottom: 1rem;
}

.p-b-half {
  padding-bottom: 0.5rem;
}

.p-b-0 {
  padding-bottom: 0;
}

/* Padding */

.p-2 {
  padding-bottom: 2rem;
  padding-top: 2rem;
}

.p-1 {
  padding-bottom: 1rem;
  padding-top: 1rem;
}

.p-half {
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}

.p-0 {
  padding-bottom: 0;
  padding-top: 0;
}
