label {
  display: block;
  font-weight: bold;
  margin: 0 0 3px 0;
}

input[type="text"],
input[type="email"],
input[type="search"],
input[type="password"],
input[type="tel"],
input[type="url"],
textarea {
  appearance: none;
  border: 1px solid var(--borderColor);
  border-radius: 5px;
  font-size: 1rem;
  padding: 10px 15px;
  width: 100%;

  &:active,
  &:focus,
  &:hover {
    border-color: var(--borderColorActvie);
    box-shadow: none;
    outline: none;
  }
}

input[type="text"][aria-invalid="true"],
input[type="email"][aria-invalid="true"],
input[type="search"][aria-invalid="true"],
input[type="password"][aria-invalid="true"],
input[type="tel"][aria-invalid="true"],
input[type="url"][aria-invalid="true"],
textarea[aria-invalid="true"],
select[aria-invalid="true"] {
  border-color: var(--error);
}

button,
.btn {
  appearance: none;
  background: var(--primary);
  border: 1px solid var(--primary);
  border-radius: 30px;
  box-shadow: none;
  color: var(--primaryText);
  display: inline-flex;
  font-family: var(--heading);
  font-weight: var(--headingWeight);
  justify-content: center;
  min-width: 195px;
  padding: 15px;
  text-transform: uppercase;
  transition: background-color 0.2s cubic-bezier(0.5, 1, 0.89, 1),
    color 0.2s cubic-bezier(0.5, 1, 0.89, 1);
}

button:disabled,
.btn[aria-disabled="true"] {
  background: var(--disble);
  border-color: var(--disble);
  color: var(--disableColour);
}

.btn--fullwidth {
  width: 100%;
}

.btn--primary:hover:not([aria-disabled="true"]),
.btn--primary:focus:not([aria-disabled="true"]) {
  background-color: var(--primaryActive);
  border-color: var(--primaryActive);
  box-shadow: none;
  outline: none;
}

.btn--secondary {
  background: transparent;
  border-color: var(--secondary);
  color: var(--secondaryText);
}

.btn--secondary:hover:not([aria-disabled="true"]),
.btn--secondary:focus:not([aria-disabled="true"]) {
  background-color: var(--secondaryActive);
}

.btn__roundicon {
  width: 60px;
  height: 60px;
  align-items: center;
  background: transparent;
  border: 1px solid var(--borderColor);
  border-radius: 50%;
  color: var(--secondaryText);
  font-size: 40px;
  justify-content: center;
  margin-bottom: 20px;
  max-width: 60px;
  min-width: 60px;
  padding-left: 0;
  padding-right: 0;

  @media (--viewport-tablet) {
    width: 100px;
    height: 100px;
    max-width: 100px;
    min-width: 100px;
  }
}

.btn--transparent {
  background: transparent;
  border-color: transparent;
  color: var(--secondaryText);
  justify-content: flex-start;
  min-width: auto;
  padding-left: 0;
  padding-right: 0;
}

.btn--tab {
  align-items: center;
  background: transparent;
  border-color: transparent;
  flex-direction: column;
  min-width: 100px;

  &[disabled] {
    background-color: inherit;
    border-color: transparent;
    opacity: 0.5;
    pointer-events: none;
  }

  &.active,
  &:hover,
  &:focus {
    .btn__roundicon {
      border-width: 2px;
    }
  }
}

.btn--transparent:hover:not([aria-disabled="true"]),
.btn--transparent:focus:not([aria-disabled="true"]) {
  color: #3c3c3c;
}

.btn__icon {
  margin-right: 5px;
}

.field-item {
  margin-bottom: 20px;

  &--relative {
    position: relative;
  }

  &__control {
    position: relative;
  }

  &__error {
    color: var(--error);
    font-size: 0.85rem;
    margin-top: 5px;
  }

  &__instructions {
    font-size: 0.85rem;
    margin-top: 5px;
  }
}

.error {
  background: var(--error);
  border: 1px solid var(--error);
  border-radius: 5px;
  color: #fff;
  margin-bottom: 16px;
  padding: 8px 16px;
}

.tab-button {
  border-color: #575756;
}
