.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: row;
}

.contentContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
}

.fields {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.submitButtons {
  display: flex;
  justify-content: space-between;
}

.forgotPasswordLink {
  position: absolute;
  right: 0;
  top: 0;
}

.terms {
  text-decoration: underline;
}
