/* Text meant only for screen readers. */
.screen-reader-text {
  width: 1px;
  height: 1px;
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  word-wrap: normal !important; /* Many screen reader and browser combinations announce broken words as they would appear visually. */
}

/* Do not show the outline on the skip link target. */
#content[tabindex="-1"]:focus {
  outline: 0;
}

:focus {
  outline: 2px auto -webkit-focus-ring-color;
}

body.using-mouse :focus {
  outline: none;
}
