.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
}

.sep {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
}
