:root {
  --heading: "Museo Sans Rounded", sans-serif;
  --headingWeight: 700;
  --body: "Museo Sans Rounded", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bodyColour: #000;
  --bodyBg: #f7f7f7;
  --linkColour: inherit;
  --linkActiveColour: #001df6;
  --primary: #1f284d;
  --primaryActive: #fb701e;
  --primaryText: #fff;
  --secondary: #646f8f;
  --secondaryText: #000;
  --secondaryActive: #ededed;
  --borderColor: #575756;
  --borderColorActvie: #000;
  --disble: #ededed;
  --disableColour: #adaeaf;
  --error: rgb(164, 38, 44);
}
