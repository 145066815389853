.header {
  align-items: flex-end;
  background: #fff;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: space-between;
  padding: 15px;
  z-index: 10;
}

.logo {
  font-family: var(--heading);
  font-weight: var(--headingWeight);
  text-transform: uppercase;
}

.logoLink {
  display: block;
  line-height: 1;
}

.navigation {
  display: block;
}

.link {
  display: inline-block;
  position: relative;
}

.activeLink:after {
  width: 100%;
  height: 1px;
  background: var(--bodyColour);
  bottom: 0;
  content: " ";
  left: 0;
  position: absolute;
}
