html,
body {
  background: var(--bodyBg);
  font-family: var(--body);
  font-size: 16px;
  line-height: 1.6;
}

html {
  height: 100%;
}

body {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: var(--heading);
  font-weight: var(--headingWeight);
  line-height: 1.2;
  margin: 0 0 0.5rem 0;
  padding: 0;
  text-transform: uppercase;
}

h1,
.h1 {
  font-size: 3rem;
}

h2,
.h2 {
  font-size: 2.5rem;
}

h3,
.h3 {
  font-size: 2rem;
}

h4,
.h4 {
  font-size: 1.5rem;
}

h5,
.h5 {
  font-size: 1rem;
}

h6,
.h6 {
  font-size: 0.85rem;
}

a {
  color: var(--linkColour);
  text-decoration: none;

  &:hover,
  &:active,
  &:focus {
    color: var(--linkActiveColour);
  }
}

blockquote {
  border-left: 3px solid var(--borderColor);
  margin: 1rem 0;
  padding-left: 1rem;
}

p {
  margin-bottom: 1rem;
}

.small {
  font-size: 0.85rem;
}

.link-inherit a {
  color: inherit;
}

.text-uppercase {
  text-transform: uppercase;
}

.instruction-remove-me {
  font-size: 0.85rem;
  font-style: italic;
}
