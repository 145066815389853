@font-face {
  font-family: "Museo Sans Rounded";
  font-style: normal;
  font-weight: normal;
  src: local("Museo Sans Rounded 300"),
    url("./fonts/MuseoSansRounded/MuseoSansRounded300.woff") format("woff");
}

@font-face {
  font-family: "Museo Sans Rounded";
  font-style: normal;
  font-weight: bold;
  src: local("Museo Sans Rounded 700"),
    url("./fonts/MuseoSansRounded/MuseoSansRounded700.woff") format("woff");
}

@font-face {
  font-family: "Museo Sans Rounded";
  font-style: normal;
  font-weight: 500;
  src: local("Museo Sans Rounded 500"),
    url("./fonts/MuseoSansRounded/MuseoSansRounded500.woff") format("woff");
}

@font-face {
  font-family: "Museo Sans Rounded";
  font-style: normal;
  font-weight: 100;
  src: local("Museo Sans Rounded 100"),
    url("./fonts/MuseoSansRounded/MuseoSansRounded100.woff") format("woff");
}

@font-face {
  font-family: "Museo Sans Rounded";
  font-style: normal;
  font-weight: 900;
  src: local("Museo Sans Rounded 900"),
    url("./fonts/MuseoSansRounded/MuseoSansRounded900.woff") format("woff");
}
