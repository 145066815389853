.list-pipe {
  list-style: none;
  margin: 0;
  padding: 0;

  &__item {
    display: inline-block;
    padding: 0 3px;
    position: relative;
  }
}
