.footer {
  width: 100%;
  height: 96px;
  background-color: #fff;
  bottom: 0;
  left: 0;
  position: fixed;
  z-index: 10;
}

.footer svg {
  max-height: 100%;
  width: 100%;
}

.content {
  display: flex;
  flex-direction: column;
}

.link:hover,
.link:active,
.link:focus {
  color: inherit;
}

.linkActive {
  color: inherit;
  font-weight: bold;
}
