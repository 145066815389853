.intro {
  margin-bottom: 40px;
}

.heading {
  font-size: 1rem;
  margin-bottom: 10px;
}

.text {
  line-height: 1.2;
  max-width: 400px;
}
