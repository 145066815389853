.container {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.inner {
  background: #fff;
  min-height: 200px;
  padding: 15px;
  width: 100%;

  @media (--viewport-tablet) {
    border-radius: 15px;
    margin: 30px auto;
    max-width: 578px;
    padding: 30px;
  }

  @media (--viewport-desktop) {
    margin: 30px auto;
    max-width: 768px;
  }

  &--no-padding {
    padding: 0;
  }

  &--full {
    flex: 1;
  }
}
