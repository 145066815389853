table {
  border-collapse: collapse;
  width: 100%;
}

table th,
table td {
  border: 1px solid var(--borderColor);
  padding: 5px;
  text-align: left;
  vertical-align: top;
}
