.sep {
  position: relative;
  text-align: center;
  width: 100%;
}

.sep::before {
  width: 100%;
  height: 1px;
  background: rgb(237, 235, 233);
  content: " ";
  left: 0;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}

.sepText {
  background: #fff;
  color: #6c7378;
  display: inline-block;
  padding: 0 0.5em;
  position: relative;
}

.vertical {
  background-color: #79cdec;
  position: relative;
  width: 2px;

  &:before {
    width: 16px;
    height: 16px;
    background-color: #79cdec;
    content: " ";
    left: -8px;
    position: absolute;
    top: 0;
  }

  &:after {
    width: 16px;
    height: 16px;
    background-color: #79cdec;
    bottom: 0;
    content: " ";
    left: -8px;
    position: absolute;
  }
}
